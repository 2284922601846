import DurationTag from "components/Tags/DurationTag";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import ChooseVehicle from "components/Package/ChooseVehicle";
import { useStateProvider } from "context/StateContext";
import Dropdown from "components/Package/Dropdown";
import { packages, reducerCases } from "context/constants";
import { PiBowlFoodLight } from "react-icons/pi";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RoomsAndGuests from "components/Package/RoomsAndGuests";
import prop1 from "assets/images/prop1.avif";
import prop2 from "assets/images/prop2.webp";
import prop3 from "assets/images/prop3.jpeg";

import Gallery from "components/Package/Gallery";
import dayjs from "dayjs";
import { getFullDate, getFullDatePackage } from "utils/HelperFuncs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PickupRadio from "components/PickupRadio";
import { FaWhatsapp } from "react-icons/fa";
import { trackButtonClicks } from "Analytics/GoogleAnalytics";
import CustomModal from "components/CustomModal";
import { Helmet } from "react-helmet";
import { HOST } from "utils/ApiRoutes";
import { PackageData } from "PackageData";

const galleryData = [
  {
    id: 0,
    img: "https://hldak.mmtcdn.com/prod-s3-hld-hpcmsadmin/holidays/images/cities/4308/Houseboat%20on%20Dal%20Lake.jpg?crop=460:300&downsize=460:300",
  },
  {
    id: 1,
    img: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/1a/64/5f/f1/caption.jpg?w=300&h=300&s=1",
  },
  {
    id: 2,
    img: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0e/b5/28/7c/chhota-imambara.jpg?w=1200&h=700&s=1",
  },
  {
    id: 3,
    img: "https://www.holidify.com/images/bgImages/DELHI.jpg",
  },
];

const PackagesCommonPage2 = () => {
  const navigate = useNavigate();
  const today = getFullDatePackage();
  const location = useLocation();

  const { title } = useParams();

  const [{ economyPackage, premiumPackage, user }, dispatch] =
    useStateProvider();
  const packageData = PackageData().filter((item) => item.title === title);
  const showData = packageData[0];
  const [packagesLength, setPackagesLength] = useState(0);

  const [summary, setsummary] = useState(false);
  const [date, setdate] = useState(today);
  const [highlightMessage, setHighlightMessage] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [customDataSend, setCustomDataSend] = useState({
    width: "80vw",
  });

  const packagesType = showData?.packagesType;
  // console.log(showData)

  useEffect(() => {
    if (!showData || !showData.title || showData.title !== title) {
      navigate("/browse-packages");
    }
  }, [showData, title, navigate]);

  // useEffect(()=>{
  //   dispatch({
  //     type: reducerCases.SET_PACKAGE_PRICE,
  //     setPrice: { op: 'reset', packageName: packagesType }
  //   });
  // },[location, navigate])

  useEffect(() => {
    if (showData) {
      window.scrollTo(0, 0);
      setPackagesLength(showData.activityItems.length + 1);
    }
  }, [location, showData]);

  // console.log(showData.basePrice)

  const handlePayment = async () => {
    trackButtonClicks(
      showData.trackPackageBtn[0].text,
      showData.trackPackageBtn[1].text,
      showData.trackPackageBtn[2].text
    );
    const { $y: year, $M: month, $D: day } = date;
    dispatch({
      type: showData.reducerCasePackageType,
      [packagesType]: {
        ...showData,
        date: `${day}-${month}-${year}`,
      },
    });
    sessionStorage.setItem(
      "currOrder",
      JSON.stringify({
        title: showData.sessionTitle,
        category: "Package",
        people: showData.guests,
        date: `${day}-${month}-${year}`,
        subTotal: parseInt(showData.price),
        orderObj: showData,
      })
    );
    dispatch({
      type: showData.reducerCasePackageCurrent_Order,
      order: {
        title: showData.sessionTitle,
        category: "Package",
        people: showData.guests,
        date: `${day}-${month}-${year}`,
        basePrice: showData.basePrice,
        subTotal: parseInt(showData.price),
        orderObj: showData,
      },
    });
    dispatch({
      type: reducerCases.SET_PACKAGE_PRICE,
      setPrice: { op: "reset", packageName: packagesType },
    });
    navigate("/order-summary");
  };

  const handleApply = (guests, rooms) => {
    if (parseInt(guests) > 4) {
      setHighlightMessage(true);
    } else {
      setHighlightMessage(false);
    }
    let xprice;
    if (guests === 3) {
      xprice = 15500;
    } else if (guests === 2) {
      xprice = 13200;
    } else if (guests === 1) {
      xprice = 11200;
    } else if (guests > 3) {
      if (parseInt(guests) % 3 === 1) {
        xprice = parseInt(parseInt(guests) / 3) * 15500 + 7500;
      } else if (parseInt(guests) % 3 === 2) {
        xprice = parseInt(parseInt(guests) / 3) * 15500 + 12000;
      } else {
        xprice = parseInt(parseInt(guests) / 3) * 15500;
      }
      // xprice = (parseInt(guests)-3)*2500 + 13500
    }

    if (showData.airportpick) {
      xprice += showData.pickupPrice;
    }

    let { dayOne, dayTwo } = premiumPackage;

    if (dayOne.vehicle === "muv") {
      console.log("enter in day one");
      xprice += showData.muvPrice;
    }
    if (dayTwo.vehicle === "muv") {
      console.log("enter in day two");
      xprice += premiumPackage.muvPrice;
    }

    dispatch({
      type: showData.reducerCasePackageType,
      [packagesType]: {
        ...showData.mainPackageName,
        guests,
        rooms,
        price: xprice,
      },
    });
  };

  const handleDateChange = (e) => {
    let bookingDate = dayjs(`${e.$y}-${e.$M + 1}-${e.$D}`);
    setdate(bookingDate);
    dispatch({
      type: showData.reducerCasePackage_date,
      setDate: {
        date: `${e.$y}-${e.$M + 1}-${e.$D}`,
        packageName: showData.packagesType,
      },
    });
  };
  const handleRemoveUjjainDarshan = () => {
    let c = window.confirm(
      "This activity will be removed from package, Are you sure you want to continue?"
    );
    if (c) {
      dispatch({
        type: showData.reducerCasePackageType,
        [packagesType]: {
          ...showData,
          price: showData.price - 1500,
          dayOne: {
            ...showData.dayOne,
            ujjainDarshan: false,
          },
        },
      });
    }
  };
  const handleCloseModel = () => {
    setShowModal(false);
    setTimeout(() => {
      setModalVisible(false);
    }, 200);
  };
  const handleImageClick = (data) => {
    setModalContent(
      <div className="relative w-full h-full">
        <img
          src={data.img}
          alt="gallery image"
          className="object-cover w-[100%]  h-[100%] md:h-[100%]"
        />
        <button
          onClick={handleCloseModel}
          className="absolute text-white font-semibold top-0 right-0 bg-red-600 p-3"
        >
          X
        </button>
      </div>
    );
    setModalVisible(true);
    setTimeout(() => {
      setShowModal(true);
    }, 100);
  };

  return (
    <>
      <Helmet>
        <title>{showData?.heading}</title>
        <link rel="canonical" href={`${HOST}/packages/economy-package`} />
        <meta
          name="description"
          content="Discover Ujjain with Avadhoot Journey’s Premium Mahakaleshwar Package. Enjoy premium services with darshan at Mahakaleshwar, luxury hotels and much more"
        />
      </Helmet>
      {modalVisible && (
        <CustomModal
          ModalContent={modalContent}
          visibleModel={showModal}
          customDataSend={customDataSend}
        />
      )}
      <div className="mb-10 min-h-[100vh]">
        <div id="booking-time" className="py-6 px-5 bg-slate-50 shadow-sm">
          <div className="flex md:flex-row md:gap-x-5 flex-col gap-y-3">
            <div className="md:ml-16 bg-white">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  onChange={handleDateChange}
                  minDate={today}
                  value={date}
                  sx={{ color: "grey", boxShadow: "10px" }}
                  disablePast
                  className="rounded-lg"
                  label="TRAVELLING ON"
                />
              </LocalizationProvider>
            </div>
            <div className="bg-white">
              <RoomsAndGuests handleApply={handleApply} />
            </div>
          </div>
        </div>
        {/* <div className="w-[100%]  flex justify-center items-center md:mt-10 md:mb-10 mt-4 mb-4">
          <div className="flex md:flex-row flex-col max-w-[1600px] gap-3 p-4 w-full  md:h-[350px]">
            <div className="relative md:w-[70%]  w-[100%]  h-full flex md:flex-row flex-col gap-3 ">
              <div
                onClick={() => handleImageClick(galleryData[0])}
                className="md:w-[55%] w-[100%] md:h-full h-[200px] relative"
              >
                <img
                  src={galleryData[0].img}
                  alt=""
                  className="h-[100%] cursor-pointer hover:opacity-80 transition-all duration-150 object-cover w-[100%] rounded-md"
                />
                <button className="absolute bg-indigo-200 text-gray-600 rounded-md hover:bg-gray-400 hover:text-white transition-all duration-200 font-semibold  py-2 px-4  bottom-3 left-3">
                  view gallery
                </button>
              </div>
              <div
                onClick={() => handleImageClick(galleryData[1])}
                className="relative md:w-[44%] w-[100%] md:h-full h-[200px]"
              >
                <img
                  src={galleryData[1].img}
                  alt=""
                  className="h-[100%] cursor-pointer hover:opacity-80 transition-all duration-150 object-cover w-[100%] rounded-md"
                />
                <p className="absolute  text-white font-semibold bottom-2 left-3">
                  Activites & Sightseeing{" "}
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-3 justify-between items-center h-full md:w-[29%] w-full  ">
              <div
                onClick={() => handleImageClick(galleryData[2])}
                className="relative md:h-[48%] h-[200px]  w-full "
              >
                <img
                  src={galleryData[2].img}
                  alt=""
                  className="h-[100%] cursor-pointer hover:opacity-80 transition-all duration-150 object-cover w-[100%] rounded-md"
                />
                <p className="absolute  text-white font-semibold    bottom-1 left-3">
                  overview
                </p>
              </div>
              <div
                onClick={() => handleImageClick(galleryData[3])}
                className="relative md:h-[48%] w-full h-[200px]"
              >
                <img
                  src={galleryData[3].img}
                  alt=""
                  className="h-[100%] cursor-pointer hover:opacity-80 transition-all duration-150 object-cover w-[100%] rounded-md"
                />
                <p className="absolute  text-white font-semibold  bottom-1 left-3">
                  Activites & Sightseeing{" "}
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <p className="leading-[20px] text-red-500 text-center md:mx-20 mx-5 py-2">
          {showData?.desc}
        </p>

        {highlightMessage && (
          <div>
            <p className="leading-[20px] text-yellow-600 bg-yellow-100 border-l-4 border-yellow-500 text-center md:mx-20 mx-5 py-2 my-4 font-semibold">
              To get the best deals on this package for 5 or more people,
              specify the requirements (people, date, time,etc) to text message
              on
              <a
                href="https://wa.me/message/H5LRCGI36GPIG1"
                className="text-green-600 underline mx-1 inline-flex items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp className="mr-1" /> WhatsApp
              </a>
              number or call
              <span className="text-blue-600 underline mx-1">
                +919201968470
              </span>
              so that we can give the discounted prices with excellent services.
            </p>
          </div>
        )}
        <div id="gallery" className="md:mx-20 mx-5">
          <h1 className="text-3xl font-bold mt-5 mb-1 ml-2">
            {showData?.heading}
          </h1>
          <div className="flex flex-row gap-x-2 ml-3">
            <DurationTag width={86} data={showData?.headingData[0].data} />
            <DurationTag width={46} data={showData?.headingData[1].data} />
          </div>
        </div>

        <div>
          <div className="flex flex-row md:mx-20 mx-5 mt-5 mb-2 gap-x-1 pl-2">
            <button className="rounded-full border border-gray-400 hover:border-none focus:bg-orange-400 focus:border-none focus:text-white py-1.5 px-5 text-black transition-all hover:bg-orange-400 hover:text-white text-center text-sm ">
              Iternary
            </button>
            <button className="rounded-full border border-gray-400 hover:border-none focus:bg-orange-400 focus:border-none focus:text-white py-1.5 px-5 text-black transition-all hover:bg-orange-400 hover:text-white text-center text-sm">
              Summary
            </button>
          </div>
          <div className="py-3 md:mx-20 mx-10 px-3">
            <PickupRadio pack={showData?.title} price={showData?.pickupPrice} />
          </div>

          <div className="shadow-xl border rounded-lg md:mx-20 mx-5 px-10">
            <Accordion type="multiple" collapsible className="w-full">
              {showData?.activityItems.map((item, i) => {
                return (
                  <div key={i}>
                    {" "}
                    <AccordionItem key={i} value={item.value}>
                      <AccordionTrigger>{item.trigger}</AccordionTrigger>
                      <AccordionContent>
                        <h3 className="text-2xl font-semibold my-1">
                          {item.content.heading.text}
                        </h3>
                        <ul className="border rounded-lg py-3">
                          {item.content.list.map((listItem, index) => (
                            <div>
                              <li className="px-2 py-3">
                                {listItem.dropdown && (
                                  <Dropdown
                                    incprice={listItem.dropdown.incprice}
                                    day={listItem.dropdown.day}
                                    packageName={listItem.dropdown.packageName}
                                  />
                                )}
                                {listItem.chooseVehicle && (
                                  <ChooseVehicle
                                    price={listItem.chooseVehicle.price}
                                    data={listItem.chooseVehicle.data}
                                    vehicle={listItem.chooseVehicle.vehicle}
                                  />
                                )}
                              </li>
                              {listItem.hotelBooking && (
                                <li className="px-2">
                                  <div className="ml-2">
                                    <h1 className="text-lg font-semibold mt-2 inline-block">
                                      <span className="header_span mr-1" />
                                      {listItem.hotelBooking.heading.text}
                                    </h1>
                                    <h3 className="text-gray-500 ml-3">
                                      {listItem.hotelBooking.description}
                                    </h3>
                                    {listItem.hotelBooking.gallery && (
                                      <div className="md:ml-10">
                                        <Gallery
                                          images={
                                            listItem.hotelBooking.gallery.images
                                          }
                                          about={
                                            listItem.hotelBooking.gallery.about
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                </li>
                              )}
                              {listItem?.mandirDarshan && (
                                <li className="px-2 mt-[-30px] py-1">
                                  <div className="ml-2">
                                    <h1 className="text-lg font-semibold mt-2 inline-block">
                                      <span className="header_span mr-1" />
                                      {listItem.mandirDarshan.heading.text}
                                    </h1>
                                    <h3 className="text-gray-500 ml-3">
                                      {listItem.mandirDarshan.description}
                                    </h3>
                                  </div>
                                </li>
                              )}

                              {listItem?.condition && (
                                <li className=" px-2 py-3">
                                  <div className="ml-2">
                                    <h1 className="text-lg font-semibold mt-2">
                                      <span className="header_span mr-1" />
                                      {listItem?.ujjainDarshanPlace?.text}
                                      <span>
                                        <button
                                          onClick={handleRemoveUjjainDarshan}
                                          className="text-[16px] font-normal text-red-500 ml-5 border-none hover:underline"
                                        >
                                          REMOVE
                                        </button>
                                      </span>
                                    </h1>
                                    <h3 className="text-gray-500 ml-3">
                                      {
                                        listItem?.ujjainDarshanPlace
                                          ?.description
                                      }
                                    </h3>
                                  </div>
                                </li>
                              )}
                            </div>
                          ))}
                        </ul>
                      </AccordionContent>
                    </AccordionItem>
                  </div>
                );
              })}
              <AccordionItem value="item-3">
                <AccordionTrigger>Day {packagesLength}</AccordionTrigger>
                <AccordionContent>
                  <h1 className="text-2xl font-semibold">
                    <span className="header_span mr-1" />
                    Check-out and Drop
                  </h1>
                  <h3 className="text-gray-500 ml-3">- Beakfast at hotel</h3>
                  <h3 className="text-gray-500 ml-3">
                    - Check-out from hotel and drop to your departure place.
                  </h3>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div id="summary"></div>
      </div>
      <div className="z-10 bg-blue-600 sticky bottom-0 py-3 px-5 flex md:flex-row gap-y-2 flex-col">
        <div>
          <h3 className=" text-white font-bold text-2xl">
            Package Price : ₹{showData?.price}
          </h3>
          <p className="text-xs text-white">Excluding applicable tax</p>
        </div>
        <button
          onClick={handlePayment}
          className="ml-3 lg:ml-[70%] rounded-full border bg-white py-1.5 px-5 text-sky-600 transition-all hover:text-sky-700 hover:shadow-2xl text-center"
        >
          Proceed to Payment
        </button>
      </div>
    </>
  );
};

export default PackagesCommonPage2;
