import { packages, reducerCases } from "context/constants";
import { useStateProvider } from "context/StateContext";
import prop1 from "assets/images/prop1.avif";
import prop2 from "assets/images/prop2.webp";
import prop3 from "assets/images/prop3.jpeg";

export const PackageData = () => {
  const [{ economyPackage, premiumPackage }] = useStateProvider();

  const data = [
    {
      title: "economyPackage",
      heading: "Premium Mahakaleshwar Package",
      rooms: 1,
      date: "",
      otherVehicle: [null, null, null],
      muvPrice: 1300,
      pickupPrice: 2500,
      airportpick: false,
      dayOne: {
        vehicle: "sedan",
        ujjainDarshan: true,
      },
      dayTwo: {
        vehicle: "sedan",
      },

      trackPackageBtn: [
        { text: "Economy_package_payment_btn" },
        { text: "custom_event" },
        { text: "To payment economy package" },
      ],
      mainPackageName : economyPackage,
      reducerCasePackageType: reducerCases.SET_ECONOMY_PACKAGE,
      reducerCasePackagePrice  :reducerCases.SET_PACKAGE_PRICE,
      packagesType: "economyPackage",
      sessionTitle: "Economy Package",
      price : economyPackage.price ,
      basePrice :economyPackage.basePrice,
      guests: economyPackage.guests,
      reducerCasePackageCurrent_Order:  reducerCases.SET_CURRENT_ORDER,
      reducerCasePackage_date: reducerCases.SET_PACKAGE_DATE,
      desc: "**Note - If arrival of travellers in Ujjain is at night/day before the day of the package is booked, then they can contact our team for their stay.** We recommend travellers to plan their arrival in Ujjain in the morning before 11:30 AM on the day of booking of the package.",
      headingData: [{ data: "Customizable" }, { data: "1N/2D" }],

      activityItems: [
        {
          value: "item-1",
          trigger: "Day 1",
          content: {
            heading: {
              text: "Ujjain",
            },
            list: [
              {
                dropdown: {
                  incprice: 1300,
                  day: "dayOne",
                  packageName: packages.ECONOMY_PACKAGE,
                },
                chooseVehicle: {
                  price: 1300,
                  data: "Transfer to hotel | Mahakaleshwar Darshan | Ujjain darsharn & sightseeing",
                  vehicle: economyPackage.dayOne.vehicle,
                },
              },
              {
                hotelBooking: {
                  heading: {
                    text: "Hotel Check-In (2⭐ or 3⭐)",
                  },
                  description:
                    "- Our recommended Hotel near temple and with peaceful environment",
                  gallery: {
                    images: [prop2, prop3],
                    about: "Property Photos",
                  },
                },
              },
              {
                mandirDarshan: {
                  heading: {
                    text: "Mahakaleshwar VIP Darshan",
                  },
                  description:
                    "- Private transfer from hotel to Mahakaleshwar mandir.",
                },
              },
              {
                condition: economyPackage.dayOne.ujjainDarshan,
                ujjainDarshanPlace: {
                  text: "Ujjain Darshan (5 temples)",
                  description:
                    "- Visit 5 Major temples, including (Kaal Bhairava, Maa Harsiddhi Temple, Mangalnath Temple, Sandinpani Aashram, Bharthari Gufa and others).",
                },
              },
            ],
          },
        },
          
        // add second data here
      ],
    },

    {
      title: "premiumPackage",
      heading: "Premium Jyotirlinga Package",
      rooms: 1,
      date: "",
      otherVehicle: [null, null, null],
      muvPrice: 1300,
      pickupPrice: 2500,
      airportpick: false,
      dayOne: {
        vehicle: "sedan",
        ujjainDarshan: true,
      },
      dayTwo: {
        vehicle: "sedan",
      },

      trackPackageBtn: [
        { text: "Economy_package_payment_btn" },
        { text: "custom_event" },
        { text: "To payment economy package" },
      ],
      mainPackageName : premiumPackage,
      reducerCasePackageType: reducerCases.SET_PREMIUM_PACKAGE,
      reducerCasePackagePrice  :reducerCases.SET_PACKAGE_PRICE,
      packagesType: "premiumPackage",
      sessionTitle: "Premium Package",
      guests: premiumPackage.guests,
      price : premiumPackage.price,
      basePrice : premiumPackage.basePrice,
      reducerCasePackageCurrent_Order: reducerCases.SET_CURRENT_ORDER,
      reducerCasePackage_date: reducerCases.SET_PACKAGE_DATE,
      desc: "**Note - If arrival of travellers in Ujjain is at night/day before the day of the package is booked, then they can contact our team for their stay.** We recommend travellers to plan their arrival in Ujjain in the morning before 11:30 AM on the day of booking of the package.**",
      headingData: [{ data: "Customizable" }, { data: "2N/3D" }],

      activityItems: [
        {
          value: "item-1",
          trigger: "Day 1",
          content: {
            heading: {
              text: "Ujjain",
            },
            list: [
              {
                dropdown: {
                  incprice: 1300,
                  day: "dayOne",
                  packageName: packages.PREMIUM_PACKAGE,
                },
                chooseVehicle: {
                  price: 1300,
                  data: "Transfer to hotel | Mahakaleshwar Darshan | Ujjain darsharn & sightseeing",
                  vehicle: premiumPackage.dayOne.vehicle,
                },
              },
              {
                hotelBooking: {
                  heading: {
                    text: "Hotel Check-In (2⭐ or 3⭐)",
                  },
                  description:
                    "- Our recommended Hotel near temple and with peaceful environment",
                  gallery: {
                    images: [prop2, prop3],
                    about: "Property Photos",
                  },
                },
              },
              {
                mandirDarshan: {
                  heading: {
                    text: "Mahakaleshwar VIP Darshan",
                  },
                  description:
                    "- Private transfer from hotel to Mahakaleshwar mandir.",
                },
              },
              {
                condition: premiumPackage.dayOne.ujjainDarshan,
                ujjainDarshanPlace: {
                  text: "Ujjain Darshan (5 temples)",
                  description:
                    "- Visit 5 Major temples, including (Kaal Bhairava, Maa Harsiddhi Temple, Mangalnath Temple, Sandinpani Aashram, Bharthari Gufa and others).",
                },
              },
            ],
          },
        },
        {
          value: "item-2",
          trigger: "Day 2",
          content: {
            heading: {
              text: "Ujjain to Omkareshwar",
            },
            list: [
              {
                dropdown: {
                  incprice: 1800,
                  day: "dayTwo",
                  packageName: packages.PREMIUM_PACKAGE,
                },
                chooseVehicle: {
                  price: 1800,
                  data: "Trip to Omkareshwar | Omkareshwar Jyotirlinga Darshan",
                  vehicle: premiumPackage.dayTwo.vehicle,
                },
              },
              {
                hotelBooking: {
                  heading: {
                    text: "Complementry Breakfast included.",
                  },
                  description: "",
                  // "gallery": {
                  //   "images": [],
                  //   "about": ""
                  // }
                },
              },
              {
                mandirDarshan: {
                  heading: {
                    text: "Sightseeing includes Omkareshwar, Mamleshwar jyotirlinga and others.",
                  },
                  description: "",
                },
              },
              {
                condition: "",
                ujjainDarshanPlace: {
                  text: "",
                  description: "",
                },
              },
            ],
          },
        },

        // add more data here
      ],
    },

    //Add more package here
  ];

  return data;
};
