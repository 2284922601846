import React from "react";
import premium from "assets/images/premium.png";
import pimg from "assets/images/Mahakal-Lok-Corridor-inauguration2.webp";
import { useStateProvider } from "context/StateContext";
import { reducerCases } from "context/constants";
import DurationTag from "components/Tags/DurationTag";
import { IoMdCheckmark } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { trackButtonClicks } from "Analytics/GoogleAnalytics";

const dummyData = [
  {
    "title": "Premium Jyotirlinga Package",
    "durationTag": [
      { "width": "45", "color": "", "data1": "2N/3D" },
      { "width": "175", "color": "red", "data1": "Jyotirlingam Tirath Special" }
    ],
    "list1": [
      { "text": "2 Adults" },
      { "text": "Origin pickup/drop" },
      { "text": "Extra Bed" },
      { "text": "2 or 3 Star Hotels" },
      { "text": "Fully customizable" }
    ],
    "list2": [
      { "text": "Mahakaleshwar special Darshan" },
      { "text": "Omkareshwar Visit" },
      { "text": "Ujjain darshan and sightseeing" }
    ],
    "description":
      "Buy this package and get best deals in hotels, food and sightseeing in Ujjain.",
    "price": "13,200",
    "image": premium,
    "trackButtonClick": [
      { "text": "Premium Package_Card_BrowsePackagePage" },
      { "text": "custom_event" },
      { "text": "To Premium Package" }
    ],
    "handleCardClickNavigate": "premiumPackage"
  },
  
  {
    "title": "Premium Mahakaleshwar Package",
    "durationTag": [
      { "width": "45", "color": "", "data1": "1N/2D" },
    ],
    "list1": [
      { "text": "2 Adults" },
      { "text": "Origin pickup/drop" },
      { "text": "Extra Bed" },
      { "text": "2 or 3 Star Hotels" },
      { "text": "Fully customizable" }
    ],
    "list2": [
      { "text": "Mahakaleshwar special Darshan" },
      { "text": "Ujjain darshan and sightseeing" }
    ],
    "description":
      "Buy this package if you are on a short trip to Ujjain and want the most of it. We offer the best deal on Hotels and sightseeing.",
    "price": "6,500",
    "image": pimg,
    "trackButtonClick": [
      { "text": "Economy_Package_Card_BrowsePackagePage" },
      { "text": "custom_event" },
      { "text": "To Economy Package" }
    ],
    "handleCardClickNavigate": "economyPackage"
  },

  // {
  //   "title": "Premium Mahakaleshwar Package",
  //   "durationTag": [
  //     { "width": "45", "color": "", "data1": "1N/2D" },
  //   ],
  //   "list1": [
  //     { "text": "2 Adults" },
  //     { "text": "Origin pickup/drop" },
  //     { "text": "Extra Bed" },
  //     { "text": "2 or 3 Star Hotels" },
  //     { "text": "Fully customizable" }
  //   ],
  //   "list2": [
  //     { "text": "Mahakaleshwar special Darshan" },
  //     { "text": "Ujjain darshan and sightseeing" }
  //   ],
  //   "description":
  //     "Buy this package if you are on a short trip to Ujjain and want the most of it. We offer the best deal on Hotels and sightseeing.",
  //   "price": "6,500",
  //   "image": pimg,
  //   "trackButtonClick": [
  //     { "text": "Economy_Package_Card_BrowsePackagePage" },
  //     { "text": "custom_event" },
  //     { "text": "To Economy Package" }
  //   ],
  //   "handleCardClickNavigate": "economy-package"
  // },
  // {
  //   "title": "Premium Mahakaleshwar Package",
  //   "durationTag": [
  //     { "width": "45", "color": "", "data1": "1N/2D" },
  //   ],
  //   "list1": [
  //     { "text": "2 Adults" },
  //     { "text": "Origin pickup/drop" },
  //     { "text": "Extra Bed" },
  //     { "text": "2 or 3 Star Hotels" },
  //     { "text": "Fully customizable" }
  //   ],
  //   "list2": [
  //     { "text": "Mahakaleshwar special Darshan" },
  //     { "text": "Ujjain darshan and sightseeing" }
  //   ],
  //   "description":
  //     "Buy this package if you are on a short trip to Ujjain and want the most of it. We offer the best deal on Hotels and sightseeing.",
  //   "price": "6,500",
  //   "image": pimg,
  //   "trackButtonClick": [
  //     { "text": "Economy_Package_Card_BrowsePackagePage" },
  //     { "text": "custom_event" },
  //     { "text": "To Economy Package" }
  //   ],
  //   "handleCardClickNavigate": "premiumPackage"
  // },

];

function PackagesCommonPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [, dispatch] = useStateProvider();

  const classes = {
    list_class: "ml-5 md:indent-[-7px] text-sm font-normal text-gray-500"
  };

  const handleCardClick = (route) => {
    dispatch({
      type: reducerCases.SET_PREV_PAGE,
      prevPage: location.pathname
    });
    navigate(`/packages/${route}`);
  };

  return (
    // <div className="h-full grid bg-red-500 place-items-center    p-2 grid-cols-1 md:grid-cols-2 md:gap-6 gap-4 lg:grid-cols-3 2xl:grid-cols-4" >
    <div className="h-full flex justify-center items-center  flex-wrap md:gap-6 gap-4 ">
      {dummyData.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            trackButtonClicks(
              item.trackButtonClick[0].text,
              item.trackButtonClick[1].text,
              item.trackButtonClick[2].text
            );
            handleCardClick(item.handleCardClickNavigate);
          }}
          className="cursor-pointer shadow-2xl max-w-sm hover:-translate-y-2 transition-transform bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700"
        >
          <img className="rounded-lg w-full h-[300px] object-cover md:min-h-[300px] md:max-h-[300px]" src={item.image} alt="" />
          <div className="p-5 ">
            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              {item.title}
            </h5>
            <div className="">
              <div className="flex flex-row gap-x-3">
                {item?.durationTag.map((tag, i) => (
                  <DurationTag
                    key={i}
                    width={tag.width}
                    color={tag.color}
                    data={tag.data1}
                  />
                ))}
              </div>
              <div className="grid grid-cols-2">
                <ul className="list-disc pb-2">
                  {item.list1.slice(0,3).map((listItem, i) => (
                    <li key={i} className={classes.list_class}>
                      {listItem.text}
                    </li>
                  ))}
                </ul>
                <ul className="list-disc">
                  {item.list1.slice(3,10).map((listItem, i) => (
                    <li key={i} className={classes.list_class}>
                      {listItem.text}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="pb-3">
                <ul className="">
                  {item.list2.map((listItem, i) => (
                    <li key={i} className="text-sm font-normal text-green-500">
                      <IoMdCheckmark className="inline-block mr-1 text-lg" />
                      {listItem.text}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <hr className="border mb-2" />
            <p className="mb-3 leading-5 text-gray-500 dark:text-gray-400">
              {item.description}
            </p>
            <div className="border rounded-md bg-slate-200 my-2 px-3 py-1">
              <p className="text-lg font-semibold py-2">Rs. {item.price}/-</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PackagesCommonPage;
