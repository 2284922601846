import { reducerCases } from "./constants";
export const initialState = {
  modal: undefined,
  poojaBooking: undefined,
  currentPooja: undefined,
  user: undefined,
  userOtp: "",
  tokens: undefined,
  prevPage: "",
  premiumPackage: {
    price: 13200,
    basePrice:13200,
    guests: 2,
    muvPrice: [1300, 1800],
    pickupPrice: 2500,
    rooms: 1,
    date: "",
    otherVehicle: [null, null, null],
    airportpick: false,
    dayOne: {
      vehicle: "sedan",
      ujjainDarshan: true,
    },
    dayTwo: {
      vehicle: "sedan",
    },
  },
  economyPackage: {
    price: 6500,
    guests: 2,
    rooms: 1,
    date: "",
    otherVehicle: [null, null, null],
    muvPrice: 1300,
    pickupPrice: 2500,
    airportpick: false,
    dayOne: {
      vehicle: "sedan",
      ujjainDarshan: true,
    },
    dayTwo: {
      vehicle: "sedan",
    },
  },
  order: {
    title: "",
    category: "",
    people: 0,
    date: "",
    subTotal: 0,
    orderObj: {},
  },
  currTemple: {
    id: "",
    name: "",
    long_desc: "",
    darshan_timing: "",
    aarti_timings: [],
    poojas: [],
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case reducerCases.SET_MODAL:
      return {
        ...state,
        modal: action.modal,
      };
    case reducerCases.SET_POOJA_BOOKING_SUMMARY:
      return {
        ...state,
        poojaBooking: action.poojaBooking,
      };
    case reducerCases.SET_CURRENT_ORDER: {
      return {
        ...state,
        order: action.order,
      };
    }
    case reducerCases.CURRENT_POOJA:
      return {
        ...state,
        currentPooja: action.currentPooja,
      };
    case reducerCases.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case reducerCases.SET_USER_OTP:
      return {
        ...state,
        userOtp: action.userOtp,
      };
    case reducerCases.SET_AUTH_TOKENS:
      return {
        ...state,
        tokens: action.tokens,
      };
    case reducerCases.SET_PREV_PAGE:
      return {
        ...state,
        prevPage: action.prevPage,
      };
    case reducerCases.SET_PREMIUM_PACKAGE:
      return {
        ...state,
        premiumPackage: action.premiumPackage,
      };
    case reducerCases.SET_VEHICLE:
      let { day, vehicle, packageName } = action.vehicleInfo;
      state[packageName][day]["vehicle"] = vehicle;
      return {
        ...state,
      };
    case reducerCases.SET_PACKAGE_DATE: {
      let { date, packageName } = action.setDate;
      state[packageName].date = date;
      return {
        ...state,
      };
    }
    case reducerCases.SET_PACKAGE_PRICE: {
      let { price, op, packageName } = action.setPrice;
      if (op === "inc") {
        state[packageName].price += price;
      } else if (op === "reset") {
        // console.log("default data " , state[packageName].basePrice)
        state[packageName].price = state[packageName].basePrice;
      } else {
        state[packageName].price -= price;
      }
      return {
        ...state,
      };
    }
    case reducerCases.SET_ECONOMY_PACKAGE:
      return {
        ...state,
        economyPackage: action.economyPackage,
      };
    case reducerCases.SET_CURRENT_TEMPLE:
      return {
        ...state,
        currTemple: action.currTemple,
      };
    case reducerCases.SET_PICKUP: {
      let { packageName, airportpick } = action.pickup;
      state[packageName].airportpick = airportpick;
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
