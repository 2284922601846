import React, { useEffect } from "react";
import { HOST } from "utils/ApiRoutes";
import { Helmet } from "react-helmet";
import PackagesCommonPage from "./PackagesCommonPage";
const BrowsePackages = () => {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <Helmet>
         <title>Avadhoot Packages</title>
         <link rel="canonical" href={`${HOST}/browse-packages`}/>
         <meta name="description" content="Explore our packages and find the best deals for travelling in Ujjain" />
      </Helmet>
      <div className="md:px-6 sm:px-15 pb-10">
        <h3 className="text-4xl text-center font-bold mt-5 mb-4 py-6">
          Our Packages
        </h3>
        <div className="flex flex-col lg:flex-row gap-20 items-center justify-center mx-5">

          <PackagesCommonPage/>     
         
        </div>
      </div>
    </>
  );
};

export default BrowsePackages;
